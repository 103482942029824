import React, { Component } from 'react'

class Booking extends Component {
   
    render() {
        return (
            <div className="booking-widget">
                <iframe src="https://bookwhen.com/pilatessculpt/iframe" frameborder="0" seamless="seamless"></iframe>
            </div>
        )
    }
}
export default Booking