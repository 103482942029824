import React from "react"
import Layout from "../components/Layout"
import SEO from "../components/Seo"
import PageHeading from "../components/PageHeading"
import Booking from "../components/Booking"
import "../assets/sass/style.scss"

const Contact = ({ props }) => {
  return (
    <Layout>
      <SEO
        title="Book Laura's Sculpt Squad"
        description="We chisel, we chill, we whittle, we work. We deliver dopamine, carve cores; we sweat, we stretch."
      />
      <PageHeading text="Book Online">
        <p>Come and join the Sculpt squad!</p>
        <p>
          We chisel, we chill, we whittle, we work. We deliver dopamine, carve
          cores; we sweat, we stretch.
        </p>
        <p>You’ll be welcomed, worked and unwound. </p>
      </PageHeading>
      <Booking />
    </Layout>
  )
}

export default Contact
